(function () {
	'use strict';

	const Cart = {
		exec(cmd, data) {
			return new Promise((resolve, reject) => {
				$.ajax({
					url: `carrinho/${cmd}`,
					type: "post",
					dataType: "json",
					data
				})
					.then(resp => {
						if (!resp.success) {
							reject(new Error(resp.msg));
						} else {
							resolve(resp);
						}

						if ("undefined" != typeof resp.redirect_url) {
							location.href = resp.redirect_url;
						}
					})
					.catch(err => {
						reject(
							new Error("Não foi possível completar a requisição.")
						);
					});
			});
		},
		addCupom(codigo) {
			return this.exec("add-cupom", { codigo });
		},
		removerCupom() {
			return this.exec('remover-cupom');
		},
		add(sku) {
			return this.exec("add", { sku });
		},
		remove(sku) {
			return this.exec("remove", { sku });
		},
		checkout(data) {
			return this.exec("checkout", data);
		}
	};

	$("body").on("click", ".btn-add-cart-item", function() {
		const $self = $(this);
		const sku = $self.data("sku") | 0;

		if ($self.data("enviando")) {
			return;
		}

		$.fancybox.showLoading();

		Cart.add(sku)
			.then(resp => {
				//alert(resp.msg);
				location.href = `${document.baseURI}carrinho`;
			})
			.catch(err => {
				alert(err.message);
			})
			.then(() => {
				$.fancybox.hideLoading();
				$self.data("enviando", false);
			});
	});

	$("body").on("click", ".btn-remove-cart-item", function() {
		const $self = $(this);
		const sku = $self.data("sku") | 0;

		if ($self.data("enviando")) {
			return;
		}

		$.fancybox.showLoading();

		Cart.remove(sku)
			.then(resp => {
				location.reload(true);
			})
			.catch(err => {
				alert(err.message);
			})
			.then(() => {
				$.fancybox.hideLoading();
				$self.data("enviando", false);
			});
	});

	$('body').on('submit', '[name=formCupom]', function() {
		const $form = $(this);

		if ($form.data('enviando')) {
			return false;
		}

		if (!$form.find('.required').validate()) {
			return false;
		}

		$form.data('enviando', true);
		$.fancybox.showLoading();

		const $cupom = $form.find('[name=cupom]');
		const codigo = $cupom.val();

		Cart.addCupom(codigo)
			.then(resp => {
				location.reload(true);
			})
			.catch(err => {
				alert(err.message);
			})
			.then(() => {
				$form.data('enviando', false);
				$.fancybox.hideLoading();
			});

		return false;
	});

	$('body').on('click', '.btn-remover-cupom', function() {
		const $self = $(this);
		if ($self.data('enviando')) {
			return false;
		}

		$self.data('enviando', true);
		$.fancybox.showLoading();

		Cart.removerCupom()
			.then(resp => {
				location.reload(true);
			})
			.catch(err => {
				alert(err.message);
			})
			.then(() => {
				$self.data('enviando', false);
				$.fancybox.hideLoading();
			});
	});

	window.Cart = Cart;

}());
